
import { Options, Vue } from "vue-class-component";
import { Answer, SelectedAnswer } from "../types/Questionnaire";

@Options({
  props: {
    name: {
      required: true,
      type: String
    },
    classes: {
      type: String
    },
    labelClasses: {
      default: "text-secondary",
      type: String
    },
    description: {
      default: null,
      type: String
    },
    optionClasses: {
      default: "space-x-2",
      type: String
    },
    model: {
      required: true
    },
    label: {
      required: true,
      type: String
    },
    options: {
      required: true,
      type: Array
    },
    multipleSelected: {
      required: true,
      type: Array
    },
    multipleText: {
      required: true,
      type: Array
    },
    multiplePoints: {
      required: true,
      type: Array
    },
    hasError: {
      required: true,
      type: Boolean
    },
    error: {
      required: true
    }
  },
  data() {
    return {
      internalModel: [],
      textInputValue: ""
    };
  },
  watch: {
    multipleSelected(newVal: string[]) {
      console.log("newVal", newVal, this.multiplePoints, this.multipleText);
      this.internalModel = newVal?.map((value, index) => ({
        question: this.name,
        answer: value,
        points: this.multiplePoints[index], // Use the corresponding element
        text: this.multipleText
      }));
      this.$emit("update:model", this.internalModel);
    },
    multipleText(newVal: string[]) {
    this.textInputValue = newVal
    },

  },
  methods: {
    async updateModel(option: Answer, isChecked: boolean) {
      // console.log("option", option);
      const index = this.internalModel.findIndex((i: SelectedAnswer) => i.answer === option.value);
      // console.log("option", option);
      if (isChecked && index === -1) {

        // If the option is checked and not already in internalModel, add it
        this.internalModel.push({
          question: this.name,
          answer: option.value,
          points: option.points,
          text: ""
        });
      } else if (!isChecked && index !== -1) {
        // If the option is unchecked and exists in internalModel, remove it
        this.internalModel.splice(index, 1);
      }

      this.$emit("update:model", this.internalModel);
    },
    async updateCustomText(option: Answer, text: string) {
      console.log("option", option);
      let index = this.internalModel.findIndex(
        (i: SelectedAnswer) => i.answer === option.value
      );

      if (index !== -1) {
        this.internalModel[index].text = text;
        this.$emit("update:model", this.internalModel);
      }
    }
  }
})
export default class FormMultipleChoiceGroup extends Vue {
}
